import Header from './header';
import React  from 'react';
import CountDown from 'react-countdown';
const Completionist = () => <span>Oggi sposi!</span>;
var date = new Date('06/29/2024');
date.setTime(date.getTime() + (15*60*60*1000));

const renderer = ({ hours, minutes, seconds, completed }) => {
  if (completed) {
    return <Completionist />;
  } else {
    return <span>{hours}:{minutes}:{seconds}</span>;
  }
};

function Home() {
    return(
      <div>
      <Header></Header> 
        <div className="container">
          <section className="w3-row">
            <div className="w3-content  d-flex wrap f-column align-items-center justify-content-center">

              <h2 className="pt-1">Benvenuti sul nostro sito!</h2>
              <p>Finalmente dopo tredici anni il 29 giugno alle 16.30 ci sposeremo e non vediamo l'ora di passare questa giornata indimenticabile insieme a tutti voi.
                Nella sezione "Comunicazioni" troverete alcune informazioni e aggiornamenti per il nostro grande giorno mentre nella sezione "Ricordi" caricheremo alcune foto.</p>

              <div className="divider"></div>
            </div>
          </section>
          <section className='w3-row'>
            Ci vediamo presto...
            <h2>
            <CountDown date={date}>
              <h2><Completionist /></h2>
            </CountDown>
            </h2>
          </section>
          <div className="divider"></div>
        </div>
      </div> 
    )
} export default Home;