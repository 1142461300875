import {Link } from 'react-router-dom';
import React  from 'react';
import HambIcon from './img/hamburgerIcon.png';

function Nav() {
    const myFunction = () => {
        var x = document.getElementById("demo");
        if (x.className.indexOf("w3-show") === -1) {
          x.className += " w3-show";
        } else { 
          x.className = x.className.replace(" w3-show", "");
        }
      };
    return (
        <span>
          <nav className="w3-bar">
          <Link className="w3-bar-item w3-button w3-hide-small entry" to="/">Home</Link>
          <Link className="w3-bar-item w3-button w3-hide-small entry" to="/Dove">Dove</Link>
          <div className="w3-bar-item w3-hide-small date">29 Giugno 2024</div>
          <Link className="w3-bar-item w3-button w3-hide-small entry" to="/Ricordi">Ricordi</Link>
          <Link className="w3-bar-item w3-button w3-hide-small entry" to="/Comunicazioni">Comunicazioni</Link>
          <div className="w3-hide-large w3-hide-medium">
            <button className="w3-bar-item w3-button w3-right w3-hide-large w3-hide-medium" onClick={myFunction}>
            <span><img alt="ham-menu" style={{width:'24px'}} src={HambIcon}></img></span></button>
          </div>
        </nav>
        <div id="demo" className='w3-bar-block w3-hide w3-hide-large w3-hide-medium'>
                <div><Link to="/">Home</Link></div>
                <div><Link to="/Dove">Dove</Link></div>
                <div><Link to="/Ricordi">Ricordi</Link></div>
                <div><Link to="/Comunicazioni">Comunicazioni</Link></div>
            </div>
        </span>
    )
} export default Nav