import React  from 'react';

function Ricordi(){
    return(
        <div className="container">
            <h2 className="pt-1">Ricordi</h2>

            <div className="d-flex f-column justify-content-center align-items-center">
                <div class="camera-img">
                </div>
                <div>
                    <p>In questa sezione troverete alcune tra le foto più belle del nostro giorno.</p>
                    <p>Tramite il link che pubblicheremo nei giorni successivi al matrimonio potrete rivivere i momenti migliori.</p>
                </div>
            </div>
            <div className="divider"></div>
        </div>
    )
} export default Ricordi;