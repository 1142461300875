import React  from 'react';

function Dove() {
    return (
            <div className="container">
                <h2 className="pt-1">Dove</h2>
                <div className="d-flex wrap justify-content-center">
                    <div class="chiesa-img width-50 width-100">
                    </div>
                    <div>
                        <p>Qui sotto vi lasciamo tutti i luoghi di interesse della giornata.</p>
                        <p>Grazie in anticipo per lo sforzo che farete per essere presenti!</p>
                    </div>
                </div>
                <div className="divider"></div>
                <div className="w3-card-2 wrap" style={{ alignItems: 'center', borderRadius: '4px', padding: '15px', width: '400px', height: '21rem' }}>
                    <div className="">
                        <h3>Villa De Winckels</h3>
                        <p>Via Sorio 30<br></br>Tregnago, Verona</p>
                        <p>16.30</p>
                        <a href='https://g.co/kgs/Qga3fi'>Qui</a>
                    </div>
                </div>
                <div className="divider"></div>
            </div>
    )
} export default Dove