import React  from 'react';
import vn1 from './img/vn1.jpg';
import vn2 from './img/vn2.jpg';
import vn3 from './img/vn3.jpg';
import vn4 from './img/vn4.jpg';
import vn5 from './img/vn5.jpg';
import vn6 from './img/vn6.jpg';
import vn7 from './img/vn7.jpg';
import vn8 from './img/vn8.jpg';
import vn9 from './img/vn9.jpg';
import vn10 from './img/vn10.jpg';
import vn11 from './img/vn11.jpg';
import vn12 from './img/vn12.jpg';

function Comunicazioni() {
    return (
        <div className="container">
            <h2 className="pt-1">Comunicazioni</h2>
            <div className="border-dotted mt-1 mb-1 width-50">
                Benvenuti nella sezione comunicazioni!
                Se volete partecipare al nostro sogno e renderlo ancora più speciale potete utilizzare l'iban:
                <p><b>IT58I0306234210000002224987</b></p>
                <p><b>Intestatario: Antonio Gilberto Ferrero</b></p>
                <p>Qui sotto alcune foto del nostro sogno!</p>
                <p>Per comunicarci eventuali intolleranze alimentari, confermarci la vostra presenza o per semplici ulteriori info, in fondo alla pagina troverete i nostri contatti:</p>
            </div>
            <figure className='figure'>
                <img src={vn1}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Monument Valley, AZ</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn2}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Colorado River, CO</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn3}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>San Francisco, CA</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn4}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Los Angeles, CA</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn5}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Goosnecks, UT</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn6}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Las Vegas, NV</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn7}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Antigua e Barbuda</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn8}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>San Francisco, CA</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn9}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Antigua e Barbuda</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn10}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Antigua e Barbuda</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn11}
                    className='width-100' alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>Yosemite, CA</h3>
                </figcaption>
            </figure>
            <figure className='figure'>
                <img src={vn12}
                    className='width-100'
                    alt=''>
                </img>
                <figcaption className='figure-caption'>
                    <h3>San Francisco, CA</h3>
                </figcaption>
            </figure>

            <div className="divider"></div>

            <div className="border-dotted mt-1 mb-1 width-50">
                    <h2>Lo sposo</h2>
                    <p><b>Antonio</b><br></br>+39 347 1711555</p>
                    <p>a.ferrero90@gmail.com</p>
                    <h2>La sposa</h2>
                    <p><b>Marta</b><br></br>+39 340 4709084</p>
                    <p>martaschettino@libero.it</p>
            </div>
            <div className="divider"></div>

        </div>
    )
} export default Comunicazioni;